<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search :moduleapi="`${this.baseApi}/assettracker`" title="Asset Tracker"
                :columns="columns" routerpath="/hr/addassettracker" :formOptions="formOptions" :newPage="true"
                :edit="edit" :add="add" :canDelete="canDelete" :viewFields="viewFields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      viewFields: [
        {
          label: 'Date',
          field: 'date',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Employee',
          field: 'employee',
          type: 'text',
          class: "col-md-4",
        }, {
          label: 'Branch',
          field: 'branch',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Product',
          field: 'AssettracketList',
          tableHead: ['Product Name', 'Status', 'Remarks'],
          tableField: [
            {
              type: 'text',
              field: 'ProductName',
            },
            {
              type: 'text',
              field: 'status',
            },
            {
              type: 'text',
              field: 'remarks',
            }

          ],
          type: 'multiple',
          class: "col-md-12",
        },
      ],
      columns: [
        {
          label: "ID",
          field: "id",
          filterOptions: {
            enabled: true,
            placeholder: "Search ID",
          },
          hidden: true,
        },
        {
          label: "Employee",
          field: "employee",
          type: 'staticdropdown',
          url: 'getUsers',
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Employee",
          },
        },
        {
          label: "Branch",
          field: "branch",
          type: "dropdown",
          url: "branch",
          responseValue: "name",
          responseValue: "name",

          filterOptions: {
            enabled: true,
            placeholder: "Search Branch",
          },
        },
        {
          label: "Date",
          field: "date",
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          url: 'getUsers',
          type: 'staticdropdown',
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: 'staticdropdown',
          url: 'getUsers',
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Asset Tracker" : "Add Asset Tracker"
          }`,
        submitRouterPath: "/hr/assettracker",
        usersDropdown: true,
        branchDropdown: true,
        designationDropdown: true,
        method: "post",
        action: "add",
        url: `${baseApi}/assettracker`,
        getEditValue: `${baseApi}/getassettrackerById`,

        inputFields: [],
      },
      edit: "",
      add: "",
      canDelete: "",
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Asset Tracker") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
